import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import he from "he";
import parse from "html-react-parser";
import axios from "axios";
import Toast from "../component/Toast";
import { SiteUrl, UploadUrl } from "../Define/Define";
import ToastClose from "../model/ActionSheetModalClose";

const Viewsupport = () => {
  const bottomRef = useRef(null);
  const cuid = localStorage.getItem("user:token");
  const location = useLocation();
  const ticketId = location.state?.ticketId;
  const [formData, setFormData] = useState({});
  const [toast, setToast] = useState({});
  const [toastClose, setToastClose] = useState({});
  const [message, setMessage] = useState([]);
  const [firstMessage, setFirstMessage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [ticketStatus, setTicketStatus] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [documentStatus, setdocumentStatus] = useState(false);

  const getTime = () => {
    const today = new Date();
    const curTime = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

    return curTime;
  };

  const fileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setdocumentStatus(true);
    setTimeout(() => {
      setdocumentStatus(false);
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const formDataWithCuid = new FormData();
      formDataWithCuid.append("fileName", selectedFile); // Append the selected file to the form data
      formDataWithCuid.append("cuid", cuid);
      formDataWithCuid.append("ticketId", ticketId);
      formDataWithCuid.append("message", formData.message || "");

      const response = await axios.post(
        `${SiteUrl}server/saveChatMessage.php`,
        formDataWithCuid,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      setLoader(false);
      const json_msg = response.data;
      // console.log(response.data);
      if (json_msg.status === 100) {
        setdocumentStatus(false);

        setFormData({});
        const dateTime = getTime();
        setMessage((prevMessage) => [
          ...prevMessage,
          { message: json_msg.msg, user: { cuid: cuid }, time: dateTime },
        ]);
        bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      } else if (json_msg.status === 101) {
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: json_msg.msg,
        });
      } else {
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: "An error occurred. Please continue.",
        });
      }
    } catch (error) {
      setToast({
        visible: true,
        type: "error",
        title: "Error",
        message: "An error occurred. Please continue.",
      });
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleToastClose = () => {
    setToastClose((prevToast) => ({ ...prevToast, visible: false }));
  };

  const handleToastModal = () => {
    setToast((prevToast) => ({ ...prevToast, visible: false }));
  };
  useEffect(() => {
    const fetchFirstTicket = async () => {
      try {
        const response = await axios.post(
          `${SiteUrl}server/fetchFirstTicket.php`,
          {
            ticketId: ticketId,
          }
        );

        const jsonDataArray = await response.data;
        const statuses = await response.data[0];

        const messages = jsonDataArray.map((item) => ({
          message: item.message,
          time: item.date,
          status: item.status,
          user: { cuid: item.cuid },
        }));
        setPreloader(false);
        setFirstMessage(messages);
        setTicketStatus(statuses);
      } catch (error) {
        // Handle error
      }
    };
    fetchFirstTicket();
  }, []);

  const fetchChatMessage = async () => {
    try {
      const response = await axios.post(
        `${SiteUrl}server/fetchChatMessage.php`,
        {
          ticketId: ticketId,
        }
      );

      const jsonDataArray = await response.data;
      setPreloader(false);

      const messages = jsonDataArray.map((item) => ({
        message: item.chat_msg,
        time: item.date,
        user: { cuid: item.cuid },
      }));

      setMessage(messages);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchChatMessage();
  }, []);

  useEffect(() => {
    const updateMessageSeen = async () => {
      try {
        const response = await axios.post(
          `${SiteUrl}server/updateMessageSeen.php`,
          {
            ticketId: ticketId,
          }
        );
        // console.log(response.data);
      } catch (error) {
        // Handle error
      }
    };
    updateMessageSeen();
  }, [ticketId]);

  const downloadPdf = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [message]);
  console.log(ticketStatus);
  return (
    <>
      {preloader && (
        <div
          id="loader"
          className="d-flex justify-content-center align-items-center bg-transparent"
          style={{ display: "block" }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <span className="headerButton goBack">
            <ion-icon
              name="chevron-back-outline"
              role="img"
              className="md hydrated"
              aria-label="chevron back outline"
            ></ion-icon>
          </span>
        </div>
        <div className="pageTitle">{ticketId}</div>
        <div className="right">
          {firstMessage &&
            firstMessage.length > 0 &&
            firstMessage.map((data, index) => {
              
              const status = parseInt(data.status, 10); // Convert status to integer
    
    if (status === 0) {
                return (
                  <ion-icon
                    key={index}
                    name="close-circle-outline"
                    data-bs-toggle="modal"
                    data-bs-target="#ModalForm"
                    onClick={() =>
                      setToastClose({
                        visible: true,
                      })
                    }
                  ></ion-icon>
                );
              }
            })}
        </div>
      </div>
      <div id="appCapsule">
        {toast.visible && (
          <Toast
            type={toast.type}
            title={toast.title}
            message={toast.message}
            onClose={handleToastModal}
          />
        )}
        {toastClose.visible && (
          <ToastClose ticket={ticketId} onClose={handleToastClose} />
        )}
        <div className="section full mt-3">
          {firstMessage &&
            firstMessage.length > 0 &&
            firstMessage.map((data, index) => {
              const { message, time } = data; // Extract message and time
              return (
                <div className="message-item user mb-3" key={index}>
                  <div className="content">
                    <div className="bubble font-14">{message}</div>
                    <div className="footer">{time}</div>
                  </div>
                </div>
              );
            })}

          {message &&
            message.length > 0 &&
            message.map((data, index) => {
              const { time } = data; // Extract message and time
              const message = JSON.parse(data.message);
              const extension = message.document
                ? message.document.split(".")[1]
                : [];
              if (data.user.cuid !== cuid) {
                if (message.document === "") {
                  return (
                    <div className="message-item mb-3" key={index}>
                      <img
                        src="assets/img/w.png"
                        alt="avatar"
                        className="avatar"
                      />
                      <div className="content">
                        <div className="bubble font-14">
                          {parse(he.decode(message.chatMessage))}
                        </div>
                        <div className="footer">{time}</div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="message-item mb-3" key={index}>
                      <img
                        src="assets/img/w.png"
                        alt="avatar"
                        className="avatar"
                      />
                      <div className="content">
                        <div className="bubble d-flex justify-content-center align-items-center flex-column">
                          <div
                            className="w-100"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={
                                extension && extension === "pdf"
                                  ? "./assets/img/pdf.png"
                                  : `${UploadUrl}${message.document}`
                              }
                              alt="photo"
                              className="imaged w160"
                              style={{ maxHeight: "120px" }}
                            />
                            {extension && extension === "pdf" ? (
                              <span
                                className="btn btn-dark fw-7"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  position: "absolute",
                                  bottom: "10px",
                                  left: "0%",
                                  display: "grid",
                                  placeContent: "center",
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  downloadPdf(
                                    `${UploadUrl}${message.document}`,
                                    `${message.document}`
                                  )
                                }
                              >
                                <ion-icon
                                  className="font-10"
                                  style={{ marginRight: "1px" }}
                                  name="cloud-download-outline"
                                ></ion-icon>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <span className="fw-5 mt-2 font-14 text-dark">
                            {parse(he.decode(message.chatMessage))}
                          </span>
                        </div>
                        <div className="footer">{time}</div>
                      </div>
                    </div>
                  );
                }
              } else {
                if (message.document === "") {
                  return (
                    <div className="message-item user mb-3" key={index}>
                      <div className="content">
                        <div className="bubble font-14">
                          {parse(he.decode(message.chatMessage))}
                        </div>
                        <div className="footer">{time}</div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="message-item user mb-3" key={index}>
                      <div className="content">
                        <div className="bubble bg-white d-flex justify-content-center align-items-center flex-column">
                          <div
                            className="w-100"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={
                                extension && extension === "pdf"
                                  ? "./assets/img/pdf.png"
                                  : `${UploadUrl}${message.document}`
                              }
                              alt="photo"
                              className="imaged w160"
                              style={{ maxHeight: "120px" }}
                            />
                            {extension && extension === "pdf" ? (
                              <span
                                className="btn btn-dark fw-7"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  position: "absolute",
                                  bottom: "10px",
                                  left: "0%",
                                  display: "grid",
                                  placeContent: "center",
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  downloadPdf(
                                    `${UploadUrl}${message.document}`,
                                    `${message.document}`
                                  )
                                }
                              >
                                <ion-icon
                                  className="font-10"
                                  style={{ marginRight: "1px" }}
                                  name="cloud-download-outline"
                                ></ion-icon>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <span className="fw-5 mt-2 font-14 text-dark">
                            {parse(he.decode(message.chatMessage))}
                          </span>
                        </div>
                        <div className="footer">{time}</div>
                      </div>
                    </div>
                  );
                }
              }
            })}
        </div>
        <div ref={bottomRef} />
      </div>
      <div className="chatFooter">
        <div className="w-100">
          {documentStatus ? (
            <div className="alert alert-dark mb-1 w-100" role="alert">
              Document attached successfully.
            </div>
          ) : null}

          {firstMessage &&
            firstMessage.length > 0 &&
            firstMessage.map((data, index) => {
              
              const status = parseInt(data.status, 10); // Convert status to integer
    
    if (status === 0) {
                return (
                  <form
                    key={index}
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <div className="fileSupport btn btn-icon btn-secondary rounded">
                      <ion-icon
                        name="add"
                        role="img"
                        className="md hydrated"
                        aria-label="close circle"
                      />
                      <input
                        type="file"
                        onChange={fileChange}
                        accept=".png,.jpg,.webp,.jpeg,.pdf"
                        name="document"
                        className="fileSupportInput"
                      />
                    </div>

                    <div className="form-group boxed">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type a message..."
                          id="message"
                          name="message"
                          value={formData.message || ""}
                          onChange={handleChange}
                        />
                        <i className="clear-input">
                          <ion-icon
                            name="close-circle"
                            role="img"
                            className="md hydrated"
                            aria-label="close circle"
                          />
                        </i>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-icon btn-primary rounded"
                      disabled={loader}
                    >
                      {loader && (
                        <span
                          className="spinner-border spinner-border-sm me-05 mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      <ion-icon
                        name="send"
                        role="img"
                        className="md hydrated"
                        aria-label="send"
                      />
                    </button>
                  </form>
                );
              }
            })}
          {firstMessage &&
            firstMessage.length > 0 &&
            firstMessage.map((data, index) => {
              
 const status = parseInt(data.status, 10); // Convert status to integer

 if (status !== 0) {
   return (
     <div
       key={index}
       style={{ width: "100%" }}
       className="d-flex justify-content-center align-items-center"
     >
       <h6 className="text-center text-danger">Ticket Closed</h6>
     </div>
   );
 }
            })}
        </div>
      </div>
    </>
  );
};

export default Viewsupport;
