import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SiteUrl } from "../Define/Define";
import axios from "axios";
import Toast from "../component/Toast";

const Login = () => {
  const navigate = useNavigate();

  const [formdata, setFormData] = useState({});
  const [otpSent, setOtpSent] = useState(false);
  const [toast, setToast] = useState({});
  const [textContent, setTextContent] = useState("");
  const [textOtp, setTextOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [counter, setCounter] = useState(30); // Counter for 30 seconds

  useEffect(() => {
    let intervalId;

    if (otpSent) {
      intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    if (counter === 0) {
      clearInterval(intervalId);
      setCounter(0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [otpSent, counter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setCounter(30);

    try {
      const response = await axios.post(`${SiteUrl}server/post.php`, formdata);
      setLoader(false);
      const json_msg = response.data;

      if (json_msg.status === 100) {
        setFormData({});
        setToast({
          visible: true,
          type: "success",
          title: "Success",
          message: "Account verified successfully.",
        });
        localStorage.setItem("user:name", json_msg.msg.name);
        localStorage.setItem("user:email", json_msg.msg.email);
        localStorage.setItem("user:token", json_msg.msg.cuid);
        localStorage.setItem("user:phone", json_msg.msg.phone);
        navigate("/home", { replace: true });
      } else if (json_msg.status === 101) {
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: "An error occurred. Please continue.",
        });
      } else if (json_msg.status === 200) {
        setToast({
          visible: true,
          type: "success",
          title: "Success",
          message: json_msg.msg,
        });
        setOtpSent(true);
      } else if (json_msg.status === 1) {
        setTextContent(json_msg.msg);
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: json_msg.msg,
        });
      } else if (json_msg.status === 2) {
        setTextOtp(json_msg.msg);
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: json_msg.msg,
        });
      }
    } catch (error) {
      setToast({
        visible: true,
        type: "error",
        title: "Error",
        message: "An error occurred. Please continue.",
      });
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInput = (event, nextInput, prevInput) => {
    handleChange(event);
    const input = event.target;
    const maxLength = parseInt(input.getAttribute("maxlength"));
    const currentLength = input.value.length;

    if (event.keyCode === 8 && currentLength === 0 && prevInput) {
      prevInput.focus();
    }

    if (currentLength >= maxLength && nextInput != null) {
      nextInput.focus();
    }
  };

  const handleToastClose = () => {
    setToast((prevToast) => ({ ...prevToast, visible: false }));
  };

  return (
    <div
      id="appCapsule"
      className="pt-3"
      style={{ height: "100vh", background: "#fff" }}
    >
      <div className="section mb-5">
        {toast.visible && (
          <Toast
            type={toast.type}
            title={toast.title}
            message={toast.message}
            onClose={handleToastClose}
          />
        )}
        <div className="splash-page px-0">
          <div className="mb-2">
            <img
              src={otpSent ? "./assets/img/otp.png" : "./assets/img/login.png"}
              alt="welcome"
              className="img-fluid mb1"
            />
          </div>
          <h2 className="mb-1 mt-3 font-16">
            {otpSent ? "OTP Verification" : "Enter your phone number or email"}
          </h2>
          <p className="font-14">
            {otpSent
              ? "Enter the OTP sent on your phone or email "
              : "We will send you the 4 digit verification code "}
            {otpSent && (
              <span
                onClick={() => setOtpSent(!otpSent)}
                className="text-primary"
              >
                <ion-icon name="create-outline"></ion-icon>
              </span>
            )}
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body pt-0 px-0">
              {!otpSent ? (
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="userId">
                      Phone Number/Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="userId"
                      id="userId"
                      onChange={handleChange}
                    />
                    <span className="userId text-danger">{textContent}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center mt-1 mb-3">
                    {[1, 2, 3, 4].map((index) => (
                      <input
                        key={index}
                        type="number"
                        className="form-control"
                        name={`input${index}`}
                        id={`input${index}`}
                        style={{ width: "40px", margin: "auto 10px" }}
                        maxLength="1"
                        onChange={(e) =>
                          handleInput(
                            e,
                            document.getElementById(`input${index + 1}`),
                            document.getElementById(
                              index === 1 ? "userId" : `input${index - 1}`
                            )
                          )
                        }
                      />
                    ))}
                  </div>
                  <div className="w-100 text-center">
                    <span className="text-danger">{textOtp}</span>
                  </div>
                </>
              )}
              {otpSent && (
                <div className="d-flex w-100">
                  <p className="mx-auto font-13">
                    Didn't receive OTP?{" "}
                    {loader ? (
                      <span
                        className="spinner-border spinner-border-sm me-05 mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <>
                        {counter !== 0 ? "Resend in" : ""}
                        <button
                          type="submit"
                          className="text-primary bg-transparent border-0"
                          disabled={loader}
                        >
                          {counter !== 0 ? counter : "Resend"}
                        </button>
                      </>
                    )}
                  </p>
                </div>
              )}
              <div className="form-group mt-1">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg me-1"
                  disabled={loader}
                >
                  {loader && (
                    <span
                      className="spinner-border spinner-border-sm me-05 mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!otpSent ? "GENERATE OTP" : "Verify & Continue"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
