import React, { useEffect, useState } from "react";
import axios from "axios";
import parse from "html-react-parser";
import he from "he";
import { SiteUrl } from "../Define/Define";
const AboutUs = () => {
  const [companyDetails, setcompanyDetails] = useState({});
  useEffect(() => {
    const companyDetails = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
          pid: "",
        });
        const response = res.data[0];
        // console.log(response);
        setcompanyDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    companyDetails();
  }, []);
  const about = companyDetails.about ? he.decode(companyDetails.about) : "";

  return (
    <>
      <div id="appCapsule">
        <div className="section full bg-white py-2 mb-3">
          <div className="wide-block pt-2 pb-1">
            <p style={{ textAlign: "justify" }}>{parse(about)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
