import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";

const Profile = () => {
  const [walletAmount, setwalletAmount] = useState(0);
  const cuid = localStorage.getItem("user:token");
  const fetchWallet = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchWallet.php`, {
        cuid: cuid,
      });
      setwalletAmount(response.data);
    } catch (error) {
      setwalletAmount(0);
    }
  };
  fetchWallet();
  const listItemStyle = {
    content: "",
    display: "block",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    height: "1px",
    background: "transparent",
  };
   const navigate = useNavigate();

   const Logout = () => {
     localStorage.clear();
     navigate("/login", { replace: false });
   };
  return (
    <>
      <div id="appCapsule">
        <div className="section wallet-card-section1 pt-2">
          <div className="wallet-card py-2">
            <div className="balance">
              <div className="left">
                <span className="title">Wallet</span>
                <h1 className="total">&#8377; {walletAmount}</h1>
              </div>
              <div className="right"></div>
            </div>
          </div>
          <div className="section full">
            <div className="row mt-2">
              <div className="col-6">
                <div className="stat-box">
                  <div className="title">Transaction</div>
                  <div className="value font-adjuzzst text-success">
                    &#8377; 0
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="stat-box">
                  <div className="title">Earned</div>
                  <div className="value font-adjuzzst text-success">
                    &#8377; 0
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section full mb-3">
            <style>
              {`
          .listview > li:after {
            ${Object.entries(listItemStyle)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
        `}
            </style>
            <div className="wallet-card mt-2 py-2 px-1">
              <ul className="listview px-0 border-0 image-listview">
                <li className="border-b">
                  <Link to="/update-profile" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="person-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">
                        Profile Details
                      </div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>

                <li className="border-b">
                  <Link to="/kyc" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="document-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">Kyc Detail</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/invoice" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="document-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">Invoice</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="wallet-card mt-2 py-2 px-1">
              <ul className="listview px-0 border-0 image-listview">
                <li className="border-b">
                  <Link to="/about" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="person-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">About</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>

                <li className="border-b">
                  <Link to="/contact" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="people-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">Contact Us</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/support" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="people-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">Support</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="wallet-card mt-2 py-2 px-1">
              <ul className="listview px-0 border-0 image-listview">
                <li className="border-b" onClick={Logout}>
                  <Link to="/about" className="item px-2 py-1">
                    <div className="icon-box border font-14 fw-6 rounded-10">
                      <ion-icon name="log-out-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-13">Logout</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="divider bg-primary mt-5 mb-2">
              <div className="icon-box bg-primary">
                <ion-icon
                  name="arrow-down"
                  role="img"
                  className="md hydrated"
                  aria-label="arrow down"
                ></ion-icon>
              </div>
            </div>{" "}
            <code className="text-center d-flex justify-content-center">
              Designed and developed by {' '}
              <Link to={"https://westonik.com/"} className="ml-1"> Westonik</Link>
            </code>
            <div className="divider bg-primary mt-2 mb-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
