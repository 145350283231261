import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
import { SiteUrl } from '../Define/Define';
const NoticeDetail = () => {
    const location = useLocation();
  const [preloader, setPreloader] = useState(true);
  const noticeId = location.state.id;
   const [Notice, setNotice] = useState({});
    useEffect(() => {
      const fetchNotice = async () => {
        try {
          const response = await axios.post(
            `${SiteUrl}server/fetchNotice.php`,
            {
              noticeId: noticeId,
            }
          );
          setNotice(response.data[0]); setTimeout(() => {
            setPreloader(false);
          }, 300);
        } catch (error) {
          setNotice([]); setTimeout(() => {
            setPreloader(false);
          }, 300);
        }
      };
      fetchNotice();
    }, [noticeId]);
     let title = Notice.notice ? he.decode(Notice.notice) : "";
     let description = Notice.description ? he.decode(Notice.description) : "";
  return (
    <div id="appCapsule">
      {preloader && (
        <div
          id="loader"
          className="d-flex justify-content-center align-items-center bg-transparent"
          style={{ display: "block" }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      <div className="listed-detail mt-3">
        <div className="icon-wrapper">
          <div className="iconbox bg-warning">
            <ion-icon
              name="notifications-outline"
              role="img"
              className="md hydrated"
              aria-label="arrow down outline"
            ></ion-icon>
          </div>
        </div>
        <h3 className="text-center mt-2 font-16">{parse(title)}</h3>
      </div>
      <div className="section">
        <div className="wallet-card">
          <p className="font-14">
            <ion-icon name="calendar-outline"></ion-icon> {Notice.date}
          </p>
          <p>{parse(description)}</p>
        </div>
      </div>
    </div>
  );
}

export default NoticeDetail