import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div className="appBottomMenu">
        <Link
          to="/home"
          className={`item ${location.pathname === "/home" ? "active" : ""}`}
        >
          <div className="col">
            <ion-icon name="home-outline" className="text-secondary"></ion-icon>
            <strong>Home</strong>
          </div>
        </Link>
        <Link
          to="/project"
          className={`item ${location.pathname === "/project" ? "active" : ""}`}
        >
          <div className="col">
            <ion-icon name="business-outline"></ion-icon>
            <strong>Project</strong>
          </div>
        </Link>
        <Link
          to="/payment"
          className={`item ${location.pathname === "/payment" ? "active" : ""}`}
        >
          <div className="col">
            <ion-icon name="wallet-outline"></ion-icon>
            <strong>Payment</strong>
          </div>
        </Link>
        <Link
          to="/support"
          className={`item ${location.pathname === "/support" ? "active" : ""}`}
        >
          <div className="col">
            <ion-icon name="people-outline"></ion-icon>
            <strong>Support</strong>
          </div>
        </Link>
        <Link
          to="/profile"
          className={`item ${location.pathname === "/profile" ? "active" : ""}`}
        >
          <div className="col">
            <ion-icon name="person-circle-outline"></ion-icon>
            <strong>Account</strong>
          </div>
        </Link>
      </div>

      <Outlet />
    </>
  );
};

export default Sidebar;
