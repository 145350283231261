import React from "react";
import { Carousel } from "react-bootstrap";

const MyCarousel = () => {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item>
        <img className="d-block w-100" src="./assets/img/website.png" alt="First slide" />
        <Carousel.Caption>
          <h3>Website Development</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src="./assets/img/app.webp" alt="Second slide" />
        <Carousel.Caption>
          <h3>App Development</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src="./assets/img/digital.png" alt="Third slide" />
        <Carousel.Caption>
          <h3>Digital Marketing</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default MyCarousel;
