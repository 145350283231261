import React from "react";

function Toast({ type = "", title = "", message = "", onClose }) {
  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
 
return (
  <>
    {type === "success" && (
      <div
        className="modal fade dialogbox show"
        id="DialogIconedSuccess"
        data-bs-backdrop="static"
        tabIndex="-1"
        style={{ background: "rgba(0,0,0,.3)", display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-icon text-success">
              <ion-icon name="checkmark-circle"></ion-icon>
            </div>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
            <div className="modal-body">{message}</div>
            <div className="modal-footer" onClick={onClose}>
              <div className="btn-inline">
                <span className="btn">CLOSE</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

    {type === "error" && (
      <div
        className="modal fade dialogbox show"
        id="DialogIconedError"
        data-bs-backdrop="static"
        tabIndex="-1"
        style={{ background: "rgba(0,0,0,.3)", display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-icon text-danger">
              <ion-icon name="close-circle"></ion-icon>
            </div>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
            <div className="modal-body">{message}</div>
            <div className="modal-footer" onClick={handleClose}>
              <div className="btn-inline">
                <span className="btn">CLOSE</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);
}

export default Toast;
