import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
import { useNavigate } from "react-router-dom";
import { SiteUrl } from "../Define/Define";
const NoticeList = () => {
   const [Notice, setNotice] = useState([]);
  const [preloader, setPreloader] = useState(true);

   const navigate = useNavigate();

useEffect(() => {
  const fetchNotice = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchNotice.php`, {
        noticeType: "Client",
      });
      setNotice(response.data);
       setTimeout(() => {
         setPreloader(false);
       }, 300);
    } catch (error) {
      setNotice([]); setTimeout(() => {
        setPreloader(false);
      }, 300);
    }
  };
  fetchNotice();
}, []);
      const goToViewNotice = (ntidP) => {
        navigate("/notice-detail", { state: { id: ntidP } });
      };
      
  return (
    <div id="appCapsule">
      {preloader && (
        <div
          id="loader"
          className="d-flex justify-content-center align-items-center bg-transparent"
          style={{ display: "block" }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      <div className="section full">
        <ul className="listview rounded-10 image-listview">
          {Notice.length > 0 ? (
            Notice.map((data) => {
              let title = data.notice ? he.decode(data.notice) : "";
              return (
                <li className="py-1 border-b" key={data.id}>
                  <div
                    onClick={() => goToViewNotice(data.id)}
                    className="item px-2 py-1 pr-1"
                  >
                    <div className="icon-box bg-warning border font-16 fw-6 rounded-10">
                      <ion-icon
                        name="notifications-outline"
                        className="text-white"
                      ></ion-icon>
                    </div>
                    <div className="in">
                      <div className="w-100">
                        <div className="project-title text-dark fw-5 font-14">
                          {title.length > 35
                            ? parse(title.substring(0, 35)) + "..."
                            : parse(title)}
                        </div>
                        <span className="font-9">{data.date}</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="alert alert-warning">No Notice Found!</div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default NoticeList