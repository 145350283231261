import React, { useState, useEffect } from "react";
import axios from "axios";
import { SiteUrl, UploadUrl } from "../Define/Define";
const Kyc = () => {
  const [formData, setFormData] = useState({});
  const [proof, setproof] = useState({});
  const cuid = localStorage.getItem("user:token");
 
  useEffect(() => {
    fetchDetail();
  }, []);


  const fetchDetail = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchDetail.php`, {
        cuid: cuid,
      });
      const jsonData = response.data[0];
      if (jsonData && jsonData.id !== "") {
setproof({ proof: jsonData.idcard_image_image });
        setFormData({
          "ID Card": jsonData.id_card_type,
          "ID card Number": jsonData.idcard_number,
          "ID Card Proof": jsonData.idcard_image_image,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="appCapsule">
      <div className="section mt-2 mb-3">
        {Object.keys(formData).map((key) => (
          <div className="form-group boxed" key={key}>
            <div
              className={`input-wrapper ${
                key === "ID Card Proof" ? "d-none" : ""
              }`}
            >
              <div className="card">
                <div className="card-body pb-0">
                  <h6
                    className="text-capitalize font-14 mb-0"
                    htmlFor={key}
                  >
                    {key}
                  </h6>
                  <p className="font-12">{formData[key] || ""}</p>
                </div>
              </div>
            </div>
            {key === "ID Card Proof" && (
              <>
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-capitalize mb-0 font-14">
                      {key}
                    </h6>

                    <img
                      src={`${UploadUrl}/client/${cuid}/${proof.proof}`}
                      className="card-img-top rounded-10"
                      alt="image"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Kyc;
