import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";

const Payment = () => {
  const [walletAmount, setwalletAmount] = useState(0);
  const [Txn, setTxn] = useState([]);
  const cuid = localStorage.getItem("user:token");
  
        useEffect(()=>{
        const fetchWallet = async () => {
        try {
            const response = await axios.post(`${SiteUrl}server/fetchWallet.php`, {
            cuid: cuid
            });
            setwalletAmount(response.data);
        } catch (error) {
            setwalletAmount(0);
        }
        };
        fetchWallet();
        },[cuid]);

         useEffect(() => {
           const fetchTxn = async () => {
             try {
               const response = await axios.post(
                 `${SiteUrl}server/fetchTxn.php`,
                 {
                   cuid: cuid,
                   limit: 6,
                 }
               );
               setTxn(response.data);
             } catch (error) {
               setTxn([]);
             }
           };
           fetchTxn();
         }, [cuid]);
  const listItemStyle = {
    content: "",
    display: "block",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    height: "1px",
    background: "transparent",
  };
  const navigate = useNavigate();
// console.log(Txn);
const goToView = (invidP) => {
  navigate("/transaction-detail", { state: { txn: invidP } });
};
  return (
    <>
      <div id="appCapsule">
        <div className="section wallet-card-section1 pt-2">
          <div className="wallet-card py-1 px-3">
            <div className="balance py-1">
              <div className="left">
                <span className="title">Wallet</span>
                <h1 className="total">&#8377; {walletAmount}</h1>
              </div>
              <div className="right"></div>
            </div>
          </div>
          <div className="section full">
            <div className="row mt-2">
              <div className="col-6">
                <div className="stat-box">
                  <div className="title">Transaction</div>
                  <div className="value font-adjuzzst text-success">
                    &#8377; 0
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="stat-box">
                  <div className="title">Earned</div>
                  <div className="value font-adjuzzst text-success">
                    &#8377; 0
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section full mb-3">
            <style>
              {`
          .listview > li:after {
            ${Object.entries(listItemStyle)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
        `}
            </style>
            <div className="wallet-card mt-2 py-2 px-1">
              <ul className="listview px-0 border-0 image-listview">
                <li>
                  <Link to="/invoice" className="item px-2 py-1">
                    <div className="icon-box border font-15 fw-6 rounded-10">
                      <ion-icon name="document-outline"></ion-icon>
                    </div>
                    <div className="in">
                      <div className="text-dark fw-5 font-14">Invoice</div>
                    </div>
                    <div>
                      <ion-icon
                        name="chevron-forward-outline"
                        className="fw-5 text-muted"
                      ></ion-icon>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="listview-title mt-2 d-flex justify-content-between align-items-center">
                <span>Transaction</span>
                <Link to={'/transaction-list'}>
                    View All
                </Link>
            </div>
            <ul className="listview bg-transparent image-listview">
              {Txn &&
                Txn.length > 0 &&
                Txn.map((data, index) => {
                  return (
                    <li
                      onClick={() => goToView(data.txnID)}
                      key={index}
                      className="rounded-10 bg-white mt-1 flex-column"
                    >
                      <div
                        className="item flex-column"
                        style={{ minHeight: "30px" }}
                      >
                        <h5
                          style={{ wordBreak: "break-all" }}
                          className="fw-6 mb-1 font-14"
                        >
                          {data.txnID}
                        </h5>
                        <div className="in">
                          <div>
                            <p className="mb-0 text-muted font-12 fw-5">
                              {data.paidDate}
                            </p>
                          </div>

                          <span className="text-success font-12 fw-7">
                            &#8377; {data.amount}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
