import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";
const Welcome = () => {
  const [companyDetails, setcompanyDetails] = useState({});

  useEffect(() => {
    const companyDetails = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
          pid: "",
        });
        const response = res.data[0];
        // console.log(response);
        setcompanyDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    companyDetails();
  }, []);
  return (
    <>
      <div id="appCapsule">
        <div className="section">
          <div className="splash-page">
            <div className="mb-2">
              <img
                src="./assets/img/welcome.png"
                alt="welcome"
                className="img-fluid mb1"
              />
            </div>
            <h2 className="mb-2 mt-3">
              WELCOME TO <br /> {companyDetails.web_name}
            </h2>
          </div>
        </div>
        <div className="section text-center mt-5">
          <Link to="/login">
            <button
              type="button"
              className="btn btn-primary btn-block btn-lg rounded me-1"
            >
              Sign in
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Welcome;
