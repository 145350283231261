import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./component/Header";
import Sidebar from './component/Sidebar';
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import Profile from "./pages/Profile";
import UpdateProfile from "./pages/UpdateProfile";
import Kyc from "./pages/Kyc";
import Project from "./pages/Project";
import ProjectDetail from "./pages/ProjectDetail";
import Invoice from "./pages/Invoice";
import InvoiceDetail from "./pages/InvoiceDetail";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Support from "./pages/Support";
import Viewsupport from "./pages/Viewsupport";
import ProtectedRoute from "./component/ProtectedRoute";
import NonProtected from "./component/NonProtected";
import Contract from "./pages/Contract";
import Payment from "./pages/Payment";
import TransactionList from "./pages/TransactionList";
import TransactionDetail from "./pages/TransactionDetail";
import NoticeDetail from "./pages/NoticeDetail";
import NoticeList from "./pages/NoticeList";
function SidebarPage({ auth, children }) {
  return (
    <>
      {auth && <Sidebar />}
      {children}
    </>
  );
}

function App() {
    const isAuthenticated = !!localStorage.getItem("user:token");

  return (
    <div>
      <Routes>
        {/* <Route path="/welcome" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/welcome" />} />
 */}

        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/home" />
            ) : (
              <Navigate to="/welcome" />
            )
          }
        />

        <Route path="/login" element={<NonProtected />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Login />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/welcome" element={<NonProtected />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Welcome />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/home" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Home" auth={true} />
                <Home />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/profile" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Account" auth={false} />
                <Profile />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/update-profile" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="Update Profile" auth={false} />
                <UpdateProfile />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/kyc" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Kyc Detail" auth={false} />
                <Kyc />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/project" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Project" auth={false} />
                <Project />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/project-detail" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <ProjectDetail />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/contract" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Contract />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/invoice" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Invoice" auth={false} />
                <Invoice />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/invoice-view" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="Invoice View" auth={false} />
                <InvoiceDetail />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/contact" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="Contact Us" auth={false} />
                <ContactUs />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/about" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="About Us" auth={false} />
                <AboutUs />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/support" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Support" auth={false} />
                <Support />
              </SidebarPage>
            }
          />
        </Route>

        <Route path="/view-support" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Viewsupport />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/payment" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Payment" auth={false} />
                <Payment />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/transaction-list" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={true}>
                <Header title="Transaction List" auth={false} />
                <TransactionList />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/notice-detail" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="Notice Detail" auth={false} />
                <NoticeDetail />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/notice-list" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="Notice List" auth={false} />
                <NoticeList />
              </SidebarPage>
            }
          />
        </Route>
        <Route path="/transaction-detail" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <SidebarPage auth={false}>
                <Header title="Transaction Detail" auth={false} />
                <TransactionDetail />
              </SidebarPage>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
