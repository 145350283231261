import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";

const ContactUs = () => {
  const [companyDetails, setcompanyDetails] = useState({});
  useEffect(() => {
    const companyDetails = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
          pid: '',
        });
        const response = res.data[0];
        // console.log(response);
        setcompanyDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    companyDetails();
  }, []);
  const StyleWallet = {
    height: "230px",
  };

  return (
    <>
      <style>
        {`
          .wallet-card-section1:before {
            ${Object.entries(StyleWallet)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
        `}
      </style>
      <div id="appCapsule">
        <div className="section wallet-card-section1 pt-2">
          <div className="w-100 text-center">
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                color: "#fff",
                fontSize: "20px",
                backgroundColor: "#f8ba04f5",
                border: "1px solid #fff",
                position: "relative",
                zIndex: "1000",
                display: "grid",
                placeContent: "center",
                margin: "0 auto",
              }}
            >
              <ion-icon name="chatbubbles-outline"></ion-icon>
            </div>
            <h4
              style={{ position: "relative" }}
              className="mt-2 mb-1 text-white"
            >
              Chat with us!
            </h4>
            <p
              style={{ position: "relative", textAlign: "center" }}
              className="font-12 text-white"
            >
              Please get in touch and we will be happy to help you.
            </p>
          </div>
          <div className="wallet-card mt-5 py-2">
            <div
              className="balance text-center flex-column"
              style={{ minHeight: "76px" }}
            >
              <h4 className="mt-2 mx-auto mb-1 d-flex align-items-center justify-content-center">
                <ion-icon
                  className="fw-6 font-16"
                  name="mail-outline"
                ></ion-icon>
                <span className="ml-1">
                  <Link to={`mailTo:${companyDetails.email}`}>
                    {companyDetails.email}
                  </Link>{" "}
                </span>
              </h4>
              <h4 className="mx-auto mb-1 d-flex align-items-center justify-content-center">
                <ion-icon
                  className="fw-6 font-16"
                  name="call-outline"
                ></ion-icon>
                <span className="ml-1 mt-0">
                  <Link to={`tel:${companyDetails.phone}`}>{companyDetails.phone}</Link>{" "}
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
