import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";
import ActionSheetModal from "../model/ActionSheetModal";
const Support = () => {
  const cuid = localStorage.getItem("user:token");
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [Support, setSupport] = useState([]);
  const navigate = useNavigate();
 const [preloader, setpreloader] = useState(true);

  const openActionSheet = () => {
    setIsActionSheetOpen(true);
  };
  useEffect(() => {
    const fetchSupport = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/fetchSupoort.php`, {
          cuid: cuid,
        });
        const response = res.data;
        setSupport(response);setpreloader(false);
      } catch (error) {
        console.log(error);setpreloader(false);
      }
    };

    fetchSupport();
  }, []);
  const closeActionSheet = () => {
    setIsActionSheetOpen(false);
  };
const goToView = (ticketIdR) => {
  navigate("/view-support", { state: { ticketId: ticketIdR } });
};
  return (
    <>
      {preloader && (
        <div
          id="loader"
          className="d-flex justify-content-center align-items-center bg-transparent"
          style={{ display: "block" }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <span className="headerButton goBack">
            <ion-icon
              name="chevron-back-outline"
              role="img"
              className="md hydrated"
              aria-label="chevron back outline"
            ></ion-icon>
          </span>
        </div>
        <div className="pageTitle">Support</div>
        <div className="right">
          <ion-icon
            name="add-circle-outline"
            data-bs-toggle="modal"
            data-bs-target="#ModalForm"
            onClick={openActionSheet}
          ></ion-icon>
        </div>
      </div>
      <div id="appCapsule">
        <div className="section px-2 mb-3 full">
          <ul className="listview bg-transparent image-listview">
            {Support &&
              Support.length > 0 &&
              Support.map((data) => {
                return (
                  <li
                    key={data.ticketId}
                    className="rounded-10 bg-white mt-1 flex-column"
                  >
                    <div
                      onClick={() => goToView(data.ticketId)}
                      className="item"
                      style={{ minHeight: "30px" }}
                    >
                      <div className="in">
                        <div>
                          <h5 className="fw-6 mb-0 font-14">{data.ticketId}</h5>
                          <p className="mb-0 text-muted font-12 fw-5">
                            {data.ptitle.length > 35
                              ? `${data.ptitle.slice(0, 35)}...`
                              : data.ptitle}
                          </p>
                          <p className="mb-0 text-muted fw-5 font-11">{data.date}</p>
                          <p className="mb-0 text-muted fw-5">
                            {data.status == 0 ? (
                              <span className="badge badge-warning">Open</span>
                            ) : (
                              <span className="badge badge-danger">Close</span>
                            )}
                          </p>
                        </div>
                        {data.newMessageCount > 0 ? (
                          <span className="badge badge-danger">
                            {data.newMessageCount}
                          </span>
                        ) : (
                          ""
                        )}
                        <span className="text-muted fw-7">
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

      {isActionSheetOpen && (
        <ActionSheetModal cuid={cuid} onClose={closeActionSheet} />
      )}
    </>
  );
};

export default Support;
