import React, { useEffect, useState } from "react";
import axios from "axios";
import { SiteUrl, UploadUrl } from "../Define/Define";
const Header = ({ title, auth = false }) => {
  const [companyDetails, setcompanyDetails] = useState({});

  useEffect(() => {
    const companyDetails = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
          pid: "",
        });
        const response = res.data[0];
        // console.log(response);
        setcompanyDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    companyDetails();
  }, []);
  return (
    <>
      <div className="appHeader bg-primary text-light">
        <div className="left">
          {auth ? (
            <span>
              <img
                src={`${UploadUrl}${companyDetails.logo}`}
                className="bg-white p-1 mt-1 mb-1 imaged"
                style={{ maxWidth: "140px", maxHeight: "48px" }}
                alt="logo"
              />
            </span>
          ) : (
            <span className="headerButton goBack">
              <ion-icon
                name="chevron-back-outline"
                role="img"
                className="md hydrated"
                aria-label="chevron back outline"
              ></ion-icon>
            </span>
          )}
        </div>
        <div className="pageTitle">{title}</div>
        <div className="right"></div>
      </div>
    </>
  );
};

export default Header;
