import React, { useState, useEffect } from "react";
import axios from "axios";
import Toast from "../component/Toast";

import { SiteUrl } from "../Define/Define";
const UpdateProfile = () => {
  const [formData, setFormData] = useState({});
    const [toast, setToast] = useState({});
  const [loader, setLoader] = useState(false);

  const cuid = localStorage.getItem("user:token");
  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchDetail.php`, {
        cuid: cuid,
      });
      const jsonData = response.data[0];
      if (jsonData && jsonData.id !== "") {

        setFormData({
          Name: jsonData.u_name,
          Email: jsonData.u_email,
          Phone: jsonData.u_phone,
          "Alternate Phone": jsonData.c_alter_phone,
          pin_code: jsonData.pin_code,
          District: jsonData.district,
          Address: jsonData.address,
          state: jsonData.state,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
const handleSubmit = async (e) => {
  e.preventDefault();
  setLoader(true);
  try {
    const formDataWithCuid = {
      ...formData,
      cuid: cuid,
    };

    const response = await axios.post(
      `${SiteUrl}server/profile-update.php`,
      formDataWithCuid
    );
    setLoader(false);
    console.log(response.data);
    const json_msg = response.data;

    if (json_msg.status === 100) {
      setToast({
        visible: true,
        type: "success",
        title: "Success",
        message: json_msg.msg,
      });
    } else if (json_msg.status === 101) {
      setToast({
        visible: true,
        type: "error",
        title: "Error",
        message: json_msg.msg,
      });
    }
  } catch (error) {
    setToast({
      visible: true,
      type: "error",
      title: "Error",
      message: "An error occurred. Please continue.",
    });
  }
};
const handleToastClose = () => {
  setToast((prevToast) => ({ ...prevToast, visible: false }));
};
  return (
    <div id="appCapsule" style={{ background: "#fff" }}>
      {toast.visible && (
        <Toast
          type={toast.type}
          title={toast.title}
          message={toast.message}
          onClose={handleToastClose}
        />
      )}
      <div className="section text-center pt-2">
        <img
          src="./assets/img/sample/avatar/avatar.png"
          alt="Avatar"
          className="imaged w100 mx-auto"
        />
      </div>

      <div className="section mt-2">
        <form onSubmit={handleSubmit}>
          <h2 className="borderAfter font-16">Contact info</h2>

          {Object.keys(formData).map((key) => (
            <div className="form-group boxed" key={key}>
              <div
                className={`input-wrapper ${
                  key === "ID Card Proof" ? "d-none" : ""
                }`}
              >
                <label className="text-capitalize label" htmlFor={key}>
                  {key}
                </label>
                <input
                  style={{ background: "#a9abad24" }}
                  readOnly={key === "ID Card" || key === "ID Card Number"}
                  type="text"
                  className="form-control"
                  name={key}
                  id={key}
                  value={formData[key] || ""}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      [key]: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          ))}

          <div className="form-button-group px-0 w-100 bg-transparent">
            <button
              type="submit"
              className="btn btn-primary btn-block btn-lg me-1"
              disabled={loader}
            >
              {loader && (
                <span
                  className="spinner-border spinner-border-sm me-05 mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfile;
