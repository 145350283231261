import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { SiteUrl } from '../Define/Define';
const TransactionDetail = () => {
    const location = useLocation();
    const txnID = location.state.id;
     const [Txn, setTxn] = useState([]);

     useEffect(() => {
       const fetchTxn = async () => {
         try {
           const response = await axios.post(`${SiteUrl}server/fetchTxn.php`, {
             txnID: txnID,
             limit: 6,
           });
           setTxn(response.data[0]);
         } catch (error) {
           setTxn([]);
         }
       };
       fetchTxn();
     }, [txnID]);
  
  return (
    <div id="appCapsule">
      <div className="section mt-2 py-5">
        <div className="listed-detail mt-3">
          <div className="icon-wrapper">
            <div className="iconbox bg-success">
              <ion-icon name="checkmark-done-outline"></ion-icon>
            </div>
          </div>
          <h3 className="text-center mt-2">Success</h3>
        </div>

        <ul className="listview flush transparent simple-listview no-space mt-3">
          <li className="flex-column justify-content-start align-items-start">
            <strong className='fw-6'>Txn Id :</strong>
            <span className='mt-1' style={{ wordBreak: "break-all" }}>{Txn.txnID}</span>
          </li>
          <li>
            <strong>Status</strong>
            <span className="text-success">Success</span>
          </li>
          <li>
            <strong>Date</strong>
            <span>{Txn.date}</span>
          </li>

          <li>
            <strong>Total Amount</strong>
            <h3 className="m-0">&#8377; {Txn.amount}</h3>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TransactionDetail