import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
import { SiteUrl, UploadUrl } from "../Define/Define";

const Contract = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pid = location.state?.pid;
  //   console.log(pid);
  const [getProjectList, setGetProjectList] = useState({});
  const [ContractTerm, setContractTerm] = useState([]);
  const [companyDetails, setcompanyDetails] = useState({});
  useEffect(() => {
    const companyDetails = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
          pid: pid,
        });
        const response = res.data[0];
        // console.log(response);
        setcompanyDetails(response);
      } catch (error) {
        console.log(error);
      }
    };

    companyDetails();
  }, [pid]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/getProjectList.php`, {
          pid: pid,
        });
        const response = res.data[0];
        // console.log(response);
        setGetProjectList(response);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, [pid]);
  useEffect(() => {
    const fetchContractTerm = async () => {
      try {
        const res = await axios.post(`${SiteUrl}server/fetchContractTerm.php`, {
          pid: pid,
        });
        const response = res.data[0];

        setContractTerm(response);
      } catch (error) {
        //  console.log(error);
      }
    };

    fetchContractTerm();
  }, []);
  const downloadPdf = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handlePrint = () => {
    const printContents = document.getElementById("appCapsule").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const decodedDescription = getProjectList.pDescription
    ? he.decode(getProjectList.pDescription)
    : "";
  const docsArray = getProjectList.docs ? getProjectList.docs.split(",") : [];
  const setSrc = (doc) => {
    navigate("/pdf", { state: { doc: doc } });
  };
  const ContractTermDecode = ContractTerm.termsContract
    ? he.decode(ContractTerm.termsContract)
    : "";
  return (
    <>
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <span className="headerButton goBack">
            <ion-icon
              name="chevron-back-outline"
              role="img"
              className="md hydrated"
              aria-label="chevron back outline"
            ></ion-icon>
          </span>
        </div>
        <div className="pageTitle">Contract</div>
        <div className="right">
          {/* <ion-icon onClick={handlePrint} name="print-outline"></ion-icon> */}
        </div>
      </div>
      <div id="appCapsule" className="pb-2">
        <div className="section full mt-3">
          <div className="invoice">
            <div className="invoiceBackgroundLogo">
              <img src={`${UploadUrl}${companyDetails.logo}`} alt="logo" />
            </div>
            <div className="invoice-page-header">
              <div className="invoice-logo">
                <img src={`${UploadUrl}${companyDetails.logo}`} alt="logo" />
              </div>
              <div className="invoice-id font-16 fw-6">Contract</div>
            </div>
            <div className="invoice-person mt-4">
              <div className="invoice-to">
                <h4>{companyDetails.web_name}</h4>
                <p>{companyDetails.email}</p>
                <p>{companyDetails.phone}</p>
                <p>{companyDetails.address}</p>
              </div>
              <div className="invoice-from pl-0">
                <h4>{getProjectList.u_name}</h4>
                <p>{getProjectList.u_email}</p>
                <p>
                  {getProjectList.address}
                  <br />
                  {getProjectList.district} <br />
                  {getProjectList.pin_code} <br />
                  {getProjectList.state}
                </p>
              </div>
            </div>
            <div className="invoice-total mt-4">
              <ul className="listview transparent simple-listview">
                <li className="fw-5">
                  Contract Number
                  <span className="text-dark fw-6">{getProjectList.prjid}</span>
                </li>
                <li className="fw-5">
                  Start Date
                  <span className="text-dark fw-6">
                    {getProjectList.startDate}
                  </span>
                </li>
                <li className="fw-5">
                  End Date
                  <span className="text-dark fw-6">
                    {getProjectList.endDate}
                  </span>
                </li>
                <li className="fw-5">
                  Contract Type
                  <span className="text-dark fw-6">Agreement</span>
                </li>
              </ul>
            </div>
            <div className="mt-2 fw-5 text-left font-14">
              <span className="text-dark">Subject:</span> <br />
              {getProjectList.ptitle}
            </div>
            <div className="mt-2 fw-5 text-left font-14">
              <span className="text-dark">Description:</span> <br />
              {getProjectList.pDescription && (
                <div>{parse(decodedDescription)}</div>
              )}
            </div>
            <div className="wallet-card py-2 px-0 shadow-none">
              <div className="section-title pl-0">Document : </div>
              {docsArray.map((doc, index) => {
                if (doc !== "") {
                  return (
                    <button
                      key={index}
                      to={`${UploadUrl}client/${getProjectList.cltuid}/${doc}`}
                      style={{
                        height: "auto",
                        background: "transparent",
                        position: "relative",
                      }}
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#DialogIconedSuccess"
                    >
                      <span
                        className="rounded-circle"
                        style={{
                          fontWeight: "900",
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                          bottom: "10px",
                          left: "20%",
                          display: "grid",
                          placeContent: "center",
                          textAlign: "center",
                        }}
                        onClick={() =>
                          downloadPdf(
                            `${UploadUrl}client/${getProjectList.cltuid}/${doc}`,
                            `${doc}`
                          )
                        }
                      >
                        <ion-icon
                          className="font-10"
                          name="cloud-download-outline"
                        ></ion-icon>
                      </span>
                      <img
                        src="../assets/img/pdf.png"
                        alt="pdf"
                        className="imaged w64"
                      />
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </div>

            <div className="invoice-total mt-4">
              <ul className="listview transparent simple-listview">
                <li>
                  <span className="font-14">Contract Amount</span>
                  <span className="totaltext text-primary">
                    &#8377; {getProjectList.pAmount}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <div className="mt-3 invoice-logo d-flex justify-content-between">
                <img
                  src={`${UploadUrl}${companyDetails.logo}`}
                  className="imaged w64"
                  alt="logo"
                />

                <div>
                  {getProjectList.prjSign ? (
                    <>
                      <img
                        src={`${UploadUrl}/${getProjectList.prjSign}`}
                        className="imaged w64"
                        alt="background-logo"
                      />
                      <br />
                      <span className="text-primary">
                        {getProjectList.apprdate}
                      </span>
                    </>
                  ) : (
                    <span className="text-primary">Signature</span>
                  )}
                </div>
              </div>
            </div>
            <div className="invoice-detail mt-4">
              <span className="font-16 fw-6">Terms & Condition</span>
              <div>{parse(ContractTermDecode)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contract;
