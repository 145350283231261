import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { SiteUrl } from '../Define/Define'
const Invoice = () => {
  const navigate = useNavigate();
  const cuid = localStorage.getItem("user:token");

    const [invoice, setinvoice] = useState([]);
    useEffect(() => {
      const fetchInvoice = async () => {
        try {
          const res = await axios.post(
            `${SiteUrl}server/fetchInvoice.php`,
            { cuid: cuid }
          );
          const response = res.data;
          setinvoice(response);
        } catch (error) {
          console.log(error);
        }
      };

      fetchInvoice();
    }, []);
    const goToView = (invidP) => {
      navigate("/invoice-view", { state: { invid: invidP } });
    }
  return (
    <>
      <div id="appCapsule">
        <div className="section px-2 mb-3 full">
          <ul className="listview bg-transparent image-listview">
            {
                invoice && (
                    invoice.length > 0 && invoice.map((data)=>{
                        return (
                          <li key={data.invoiceId} className="rounded-10 bg-white mt-1 flex-column">
                            <div onClick={()=>goToView(data.invoiceId)} className="item" style={{ minHeight: "30px" }}>
                                <div className="in">
                                  <div>
                                    <h5 className="fw-6 mb-1 font-14">
                                      {data.invoiceId}
                                    </h5>
                                    <p className="mb-0 text-muted fw-5">
                                      {data.invoiceDate}
                                    </p>
                                  </div>

                                  <span className="text-success fw-7">
                                    &#8377; {data.pAmount}
                                  </span>
                                </div>
                            </div>
                          </li>
                        );
                    })
                )
            }
          </ul>
        </div>
      </div>
    </>
  );
}

export default Invoice