import React, { useState } from "react";
import axios from "axios";
import { SiteUrl } from "../Define/Define";
import Toast from "../component/Toast";

function ToastClose({ ticket , onClose }) {
  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState({});
  const tktid = ticket;
const closeChat = async () => {
    setLoader(true);
    try {
        const response = await axios.post(`${SiteUrl}server/closeChat.php`, {
          tktid: tktid,
        });
        setLoader(false);
      const json_msg = response.data;
        
      if (json_msg.status === 100) {
        setToast({
          visible: true,
          type: "success",
          title: "Success",
          message: "Ticket closed successfully.",
        });
        setTimeout(()=>{window.location.reload()},1200);
    }
      } catch (error) {
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: "An error occurred. Please continue.",
        });
        // console.log(error);
        setLoader(false);
      }
}
const handleToastClose = () => {
  setToast((prevToast) => ({ ...prevToast, visible: false }));
};
  return (
    <>
      {toast.visible && (
        <Toast
          type={toast.type}
          title={toast.title}
          message={toast.message}
          onClose={handleToastClose}
        />
      )}
      <div
        className="modal fade dialogbox show"
        id="DialogIconedSuccess"
        data-bs-backdrop="static"
        tabIndex="-1"
        style={{ background: "rgba(0,0,0,.3)", display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-icon text-warning">
              <ion-icon name="alert-circle-outline"></ion-icon>
            </div>

            <div className="modal-header">
              <h5 className="modal-title">Are you sure ?</h5>
            </div>
            <div className="modal-body">want to close this ticket !</div>
            <div className="modal-footer">
              <div className="btn-inline">
                <span className="btn" onClick={handleClose}>
                  CLOSE
                </span>
                <span onClick={closeChat} className="btn btn-primary">
                  {loader && (
                    <span
                      className="spinner-border spinner-border-sm me-05 mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Sure
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ToastClose;
