import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SiteUrl, UploadUrl } from "../Define/Define";
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
const InvoiceDetail = () => {
  const location = useLocation();
  const invid = location.state?.invid;
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [getInvoiceSum, setgetInvoiceSum] = useState({});
  const [data, setData] = useState([]);
  const [paymentHistory, setpaymentHistory] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [InvoiceTerm, setInvoiceTerm] = useState([]);
  const [companyDetails, setcompanyDetails] = useState({});
    useEffect(() => {
      const companyDetails = async () => {
        try {
          const res = await axios.post(`${SiteUrl}server/companyDetails.php`, {
            invid: invid,
          });
          const response = res.data[0];
          console.log(response);
          setcompanyDetails(response);
        } catch (error) {
          console.log(error);
        }
      };

      companyDetails();
    }, [invid]);
  useEffect(() => {
    const getInvoiceSum = async () => {
      try {
        const res = await axios.post(
          `${SiteUrl}server/getInvoiceSum.php`,
          { invid: invid }
        );
        const response = res.data;
        setgetInvoiceSum(response);
      } catch (error) {
        console.log(error);
      }
    };

    getInvoiceSum();
  }, [invid]);
  useEffect(() => {
    const fetchInvoiceDetail = async () => {
      try {
        const res = await axios.post(
          `${SiteUrl}server/fetchInvoiceDetail.php`,
          { invid: invid }
        );
        const response = res.data[0];
        setInvoiceDetail(response);
        setData(JSON.parse(response.description));
      } catch (error) {
        console.log(error);
      }
    };

    fetchInvoiceDetail();
  }, [invid]);

  useEffect(() => {
    const setpaymentHistoryFun = async () => {
      try {
        const res = await axios.post(
          `${SiteUrl}server/setpaymentHistory.php`,
          { invid: invid }
        );
        const response = res.data;
        setpaymentHistory(response);
      } catch (error) {
        console.log(error);
      }
    };

    setpaymentHistoryFun();
  }, [invid]);
     useEffect(() => {
       const fetchInvoiceTerm = async () => {
         try {
           const res = await axios.post(
             `${SiteUrl}server/fetchInvoiceTerm.php`,
             { invid: invid }
           );
           const response = res.data[0];

           setInvoiceTerm(response);
         } catch (error) {
           console.log(error);
         }
       };

       fetchInvoiceTerm();
     }, []);

  useEffect(() => {
    let amount = 0;
    data.forEach((item) => {
      amount += parseFloat(item.amount);
    });
    setTotalAmount(amount);
  }, [data]);

  const handlePrint = () => {
    const printContents = document.getElementById('appCapsule').innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const InvoiceTermDecode = InvoiceTerm.termsInvoice
    ? he.decode(InvoiceTerm.termsInvoice)
    : "";
  return (
    <>
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <span className="headerButton goBack">
            <ion-icon
              name="chevron-back-outline"
              role="img"
              className="md hydrated"
              aria-label="chevron back outline"
            ></ion-icon>
          </span>
        </div>
        <div className="pageTitle">Invoice Detail</div>
        <div className="right">
          {" "}
          {/* <ion-icon onClick={handlePrint} name="print-outline"></ion-icon> */}
        </div>
      </div>
      <div id="appCapsule" className="pb-2">
        <div className="section full mt-3">
          <div className="invoice">
            <div className="invoiceBackgroundLogo">
              <img src={`${UploadUrl}${companyDetails.logo}`} alt="logo" />
            </div>
            <div className="invoice-page-header">
              <div className="invoice-logo">
                <img src={`${UploadUrl}${companyDetails.logo}`} alt="logo" />
              </div>
              <div className="invoice-id">{invoiceDetail.invoiceId}</div>
            </div>
            <div className="invoice-person mt-4">
              <div className="invoice-to">
                <h4>{companyDetails.web_name}</h4>
                <p>{companyDetails.email}</p>
                <p>{companyDetails.phone}</p>
                <p>{companyDetails.address}</p>
              </div>
              <div className="invoice-from pl-0">
                <h4>{invoiceDetail.clName}</h4>
                <p>{invoiceDetail.clEmail}</p>
                <p>
                  {invoiceDetail.clAddress}
                  <br />
                  {invoiceDetail.clDistrict} <br />
                  {invoiceDetail.clPin_code}
                </p>
              </div>
            </div>
            <div className="invoice-detail text-right mt-2">
              {getInvoiceSum.status && (
                <div
                  dangerouslySetInnerHTML={{ __html: getInvoiceSum.status }}
                />
              )}
            </div>

            <div className="invoice-detail mt-4">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td>Payment Summary</td>
                      <td>Quantity</td>
                      <td>Rate (₹)</td>
                      <td>Amount (₹)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span>
                            {item.title.charAt(0).toUpperCase() +
                              item.title.slice(1)}
                          </span>
                          <br />

                          <p className="text-muted">
                            {" "}
                            {item.description.charAt(0).toUpperCase() +
                              item.description.slice(1)}
                          </p>
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.rate}</td>
                        <td>{item.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* <div className="invoice-signature mt-4">
              <div className="signature-block">Signature Here</div>
            </div> */}

            <div className="invoice-total mt-4">
              <ul className="listview transparent simple-listview">
                <li>
                  Discount{" "}
                  <span className="hightext">
                    &#8377; {invoiceDetail.discount}
                  </span>
                </li>
                <li>
                  Subtotal{" "}
                  <span className="hightext">&#8377; {totalAmount}</span>
                </li>
                <li>
                  Tax <span className="hightext">{invoiceDetail.tax}%</span>
                </li>
                <li>
                  Total
                  <span className="totaltext text-primary">
                    &#8377; {invoiceDetail.totalAmount}
                  </span>
                </li>
                <li>
                  Total Due
                  <span className="totaltext text-primary">
                    &#8377; {getInvoiceSum.unpaid}
                  </span>
                </li>
              </ul>
            </div>

            <div className="invoice-bottom text-left font-15">
              <span className="text-dark">Note :</span> {invoiceDetail.note}
            </div>

            <div className="invoice-detail mt-4">
              <span className="font-15 fw-6">Payment History</span>
              <ul className="listview bg-transparent image-listview">
                {paymentHistory.length > 0 &&
                  paymentHistory.map((history, index) => {
                    return (
                      <li
                        key={history.id}
                        className="border-b rounded-10 bg-white mt-1 flex-column"
                      >
                        <div
                          className="item px-1"
                          style={{ minHeight: "30px" }}
                        >
                          <div className="in" style={{ flexWrap: "wrap" }}>
                            <div>
                              <h5
                                style={{ wordBreak: "break-all" }}
                                className="fw-6 mb-1 font-15"
                              >
                                {history.txnID}
                              </h5>
                              <p className="mb-0 text-muted fw-5">
                                {history.date}
                              </p>
                            </div>

                            <span className="text-success fw-7">
                              &#8377; {history.amount}
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="invoice-detail mt-4">
              <span className="font-15 fw-6">Terms & Condition</span>
              <div>{parse(InvoiceTermDecode)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetail;
