import React from 'react'

const RowList = ({title, desc}) => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-6 col-sm-6">
          <h5 className="font-12 fw-5 mb-0">
            <span>{title}</span>
          </h5>
        </div>
        <div className="col-6 col-sm-6 d-flex justify-content-end align-items-end">
          <span className="font-14 fw-5">{desc}</span>
        </div>
      </div>
    </>
  );
}

export default RowList