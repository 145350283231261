import React, { useState , useEffect} from "react";
import MyCarousel from './Carousel';
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import he from "he";
import { SiteUrl } from "../Define/Define";
import FetchTask from "./fetchTask";
const Home = () => {

   const navigate = useNavigate();

   const [projects, setProjects] = useState([]);
   const [Notice, setNotice] = useState([]);
   const cuid = localStorage.getItem("user:token");

   useEffect(() => {
     fetchProject();
   }, []);
useEffect(() => {
  const fetchNotice = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchNotice.php`, {
        noticeType:'Client',
      });
      setNotice(response.data);
    } catch (error) {
      setNotice([]);
    }
  };
  fetchNotice();
}, []);
// console.log(Notice);
   const fetchProject = async () => {
     try {
       const response = await axios.post(`${SiteUrl}server/fetchProject.php`, {
         cuid: cuid,
       });
       setProjects(response.data);
     } catch (error) {
       setProjects([]);
     }
   };
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Night";
    }
  };
  const getIcon = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 12) {
      return <ion-icon name="partly-sunny-outline"></ion-icon>;
    } else if (currentHour >= 12 && currentHour < 18) {
      return <ion-icon name="sunny-outline"></ion-icon>;
    } else {
      return <ion-icon name="moon-outline"></ion-icon>;
    }
  };
   const goToView = (invidP) => {
     navigate("/project-detail", { state: { id: invidP } });
   };
      const goToViewNotice = (ntidP) => {
        navigate("/notice-detail", { state: { id: ntidP } });
      };
   
const listItemStyle = {
  content: "",
  display: "block",
  position: "absolute",
  left: "0",
  right: "0",
  bottom: "0",
  height: "1px",
  background: "transparent",
};
  return (
    <>
      <style>
        {`
          .listview > li:after {
            ${Object.entries(listItemStyle)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
        `}
      </style>
      <div id="appCapsule">
        <div className="section wallet-card-section1 pt-1">
          <div className="container p-0 mt-2">
            <div className="row mt-2">
              <div className="col-12">
                <div className="stat-box px-3 py-1 d-flex justify-content-between">
                  <div>
                    <div className="section-title medium mb-0 pb-0 fw-6 font-16">
                      {getGreeting()}
                    </div>
                    <div className="section-title mt-0 pt-0 font-16 fw-5">
                      {localStorage.getItem("user:name")}
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: "50px" }}>{getIcon()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <MyCarousel></MyCarousel>
        </div>
        <div className="section mb-3 mt-3">
          <div className="section-title font-16">Project</div>
          <ul className="listview rounded-10 image-listview">
            {projects.length > 0 ? (
              projects.map((project) => {
                return (
                  <li className="py-1 border-b" key={project.prjid}>
                    <div
                      onClick={() => goToView(project.id)}
                      className="item px-2 py-1 pr-1"
                    >
                      <div className="icon-box border font-16 fw-6 rounded-10">
                        <ion-icon name="business-outline"></ion-icon>
                      </div>
                      <div className="in">
                        <div className="w-100">
                          <div className="project-title text-dark fw-5 font-14">
                            {project.ptitle.length > 35
                              ? project.ptitle.substring(0, 35) + "..."
                              : project.ptitle}
                          </div>

                          <FetchTask pid={project.id} />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="alert alert-warning">No Projects Found!</div>
            )}
          </ul>
        </div>

        <div className="section mb-3 mt-3">
          <div className="section-title font-16 mt-2 d-flex justify-content-between align-items-center">
            <span>Notice</span>
            <Link to={"/notice-list"}>View All</Link>
          </div>
          <ul className="listview rounded-10 image-listview">
            {Notice.length > 0 ? (
              Notice.map((data) => {
                let title = data.notice ? he.decode(data.notice) : "";
                return (
                  <li className="py-1 border-b" key={data.id}>
                    <div
                      onClick={() => goToViewNotice(data.id)}
                      className="item px-2 py-1 pr-1"
                    >
                      <div className="icon-box bg-warning border font-16 fw-6 rounded-10">
                        <ion-icon
                          name="notifications-outline"
                          className="text-white"
                        ></ion-icon>
                      </div>
                      <div className="in">
                        <div className="w-100">
                          <div className="project-title text-dark fw-5 font-14">
                            {title.length > 35
                              ? parse(title.substring(0, 32)) + "..."
                              : parse(title)}
                          </div>
                          <span className="font-9">{data.date}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="alert alert-warning">No Notice Found!</div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home