import React, { useState, useEffect } from "react";
import axios from "axios";
import { SiteUrl } from "../Define/Define";
import Toast from "../component/Toast";

const ActionSheetModal = ({ onClose, cuid }) => {
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({});
  const [toast, setToast] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchProject.php`, {
        cuid: cuid,
      });
      setProjects(response.data);
    } catch (error) {
      setProjects([]);
    }
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const formDataWithCuid = {
        ...formData,
        cuid: cuid,
      };

      const response = await axios.post(
        `${SiteUrl}server/support.php`,
        formDataWithCuid
      );
      setLoader(false);
      console.log(response.data);
      const json_msg = response.data;

      if (json_msg.status === 100) {
        setToast({
          visible: true,
          type: "success",
          title: "Success",
          message: json_msg.msg,
        });
        setTimeout(() => {
          window.location.reload(); // Reload the page
        }, 600);
      } else if (json_msg.status === 101) {
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: json_msg.msg,
        });
      } else {
        setToast({
          visible: true,
          type: "error",
          title: "Error",
          message: "An error occurred. Please continue.",
        });
      }
    } catch (error) {
      setToast({
        visible: true,
        type: "error",
        title: "Error",
        message: "An error occurred. Please continue.",
      });
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleToastClose = () => {
    setToast((prevToast) => ({ ...prevToast, visible: false }));
  };
  const categories = [
    { id: 1, title: "Bug" },
    { id: 2, title: "Hacked" },
    { id: 3, title: "Service" },
    { id: 4, title: "Payment" },
    { id: 5, title: "Refund" },
  ];



  return (
    <div className="R_actionsheet-modal">
      <div className="R_actionsheet-overlay" onClick={onClose}></div>
      <div className="R_actionsheet-content">
        {toast.visible && (
          <Toast
            type={toast.type}
            title={toast.title}
            message={toast.message}
            onClose={handleToastClose}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="prjid">
                    Project
                  </label>
                  <select
                    className="form-control form-select"
                    id="prjid"
                    name="prjid"
                    onChange={handleChange}
                  >
                    <option value="">Select Project</option>
                    {projects.length > 0 &&
                      projects.map((data, index) => (
                        <option key={index} value={data.prjid}>
                          {data.ptitle}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="category">
                    Category
                  </label>
                  <select
                    className="form-control form-select"
                    id="category"
                    name="category"
                    onChange={handleChange}
                  >
                    <option value="">
                      Select Category
                    </option>
                    {categories.length > 0 &&
                      categories.map((data) => (
                        <option key={data.id} value={data.title}>
                          {data.title}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="address5">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    onChange={handleChange}
                    rows="2"
                    className="form-control"
                  ></textarea>
                  <i className="clear-input">
                    <ion-icon
                      name="close-circle"
                      role="img"
                      className="md hydrated"
                      aria-label="close circle"
                    ></ion-icon>
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-group d-flex px-0 w-100 bg-transparent">
            <button
              type="submit"
              className="btn btn-primary btn-block btn-md me-1"
              disabled={loader}
            >
              {loader && (
                <span
                  className="spinner-border spinner-border-sm me-05 mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save
            </button>
            <button
              className="btn btn-secondary mt-0 ml-1 btn-block btn-md me-1"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActionSheetModal;
