import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";

const TransactionList = () => {
  const [Txn, setTxn] = useState([]);
  const cuid = localStorage.getItem("user:token");
  const [preloader, setpreloader] = useState(true);

  useEffect(() => {
    const fetchTxn = async () => {
      try {
        const response = await axios.post(`${SiteUrl}server/fetchTxn.php`, {
          cuid: cuid,
          limit: 6,
        });
        setpreloader(false);
        setTxn(response.data);
      } catch (error) {
        setTxn([]);
      }

    };
    fetchTxn();
  }, [cuid]);
  
  const navigate = useNavigate();
  // console.log(Txn);
  const goToView = (invidP) => {
    navigate("/transaction-detail", { state: { txn: invidP } });
  };
  return (
    <>
      <div id="appCapsule">
        {preloader && (
          <div
            id="loader"
            className="d-flex justify-content-center align-items-center bg-transparent"
            style={{ display: "block" }}
          >
            <div className="spinner-border text-dark" role="status"></div>
          </div>
        )}
        <div className="section full mb-3">
          <ul className="listview bg-transparent image-listview">
            {Txn &&
              Txn.length > 0 &&
              Txn.map((data, index) => {
                return (
                  <li
                    onClick={() => goToView(data.txnID)}
                    key={index}
                    className="rounded-0 bg-white mt-1 flex-column"
                  >
                    <div
                      className="item flex-column"
                      style={{ minHeight: "30px" }}
                    >
                      <h5
                        style={{ wordBreak: "break-all" }}
                        className="fw-6 mb-1 font-13"
                      >
                        {data.txnID}
                      </h5>
                      <div className="in">
                        <div>
                          <p className="mb-0 text-muted font-12 fw-5">
                            {data.paidDate}
                          </p>
                        </div>

                        <span className="text-success font-12 fw-7">
                          &#8377; {data.amount}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TransactionList;
