import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SiteUrl } from "../Define/Define";
import FetchTask from "./fetchTask";
const Project = () => {
    const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const cuid = localStorage.getItem("user:token");
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    try {
      const response = await axios.post(`${SiteUrl}server/fetchProject.php`, {
        cuid: cuid,
      });
      setProjects(response.data);
    } catch (error) {
      setProjects([]);
    }
  };
const listItemStyle = {
  content: "",
  display: "block",
  position: "absolute",
  left: "0",
  right: "0",
  bottom: "0",
  height: "1px",
  background: "transparent",
};
 const goToView = (invidP) => {
   navigate("/project-detail", { state: { id: invidP } });
 };
  useEffect(() => {
    setTimeout(() => {
      setPreloader(false);
    }, 1200);
  }, []);
  return (
    <>
      <style>
        {`
          .listview > li:after {
            ${Object.entries(listItemStyle)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
        `}
      </style>
      {preloader && (
        <div
          id="loader"
          className="d-flex justify-content-center align-items-center bg-transparent"
          style={{ display: "block" }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      <div id="appCapsule">
        <div className="section mb-3 full">
          <ul className="listview rounded-10 image-listview">
            {projects.length > 0 ? (
              projects.map((project) => {
                return (
                  <li className="py-2 border-b" key={project.prjid}>
                    <div
                      onClick={() => goToView(project.id)}
                      className="item px-2 py-1"
                    >
                      <div className="icon-box border font-15 fw-6 rounded-10">
                        <ion-icon name="business-outline"></ion-icon>
                      </div>
                      <div className="in">
                        <div className="w-100">
                          <div className="project-title text-dark fw-5 font-13">
                            {project.ptitle.length > 35
                              ? project.ptitle.substring(0, 35) + "..."
                              : project.ptitle}
                          </div>

                          <FetchTask pid={project.id} />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="alert alert-warning rounded-0 d-flex justify-content-center align-items-center py-2">No Projects Found !</div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Project;
